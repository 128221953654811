import { Alert, Snackbar } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";
import { FC, useCallback, useEffect, useState } from "react";
import { Element } from "react-scroll";
import { getApps } from "../apis/relevant-catalog";
import { Catalog } from "../apis/search-catalog";
import { AppsContainer } from "../modules/apps/apps.container";
import { CategoryBannerContainer } from "../modules/category/category-banner.container";
import { DownloadAppMobile } from "../modules/downloadAppMobile/downloadAppMobile";
import { FavoriteServicesContainer } from "../modules/favorite-services/favorite-services.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { HeaderContainer } from "../modules/header/header.container";
import { IndicatorsSectionContainer } from "../modules/indicators-section/indicators-section.container";
import { MainBannerContainer } from "../modules/main-banner/main-banner.container";
import { OmbudsmanContainer } from "../modules/ombudsman/ombudsman.container";
import { PublicRelatedCards } from "../modules/public-related-cards/public-related-cards";
import { RelevantServicesContainer } from "../modules/relevant-services/relevant-services.container";
import { SearchContainer } from "../modules/search/search.container";
import { SocialNetworkContainer } from "../modules/social-network/social-network.container";
import { TopBar } from "../modules/top-bar/top-bar";
import { TopServicesContainer } from "../modules/top-services/top-services.container";
import { UserRelatedWidgetsContainer } from "../modules/user-related-widgets/user-related-widgets.container";
import { CategoryBannerContextProvider } from "../providers/category-banner-provider";

export const Home: FC = () => {
  const [allCatalogList, setAllCatalogList] = useState<Catalog[]>();
  const { accessToken, userInfo } = useWebgate();
  const [favoriteList, setFavoriteList] = useState<string[]>([]);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [messageAlert, setMessageAlert] = useState<string>("");
  const [severityAlert, setSeverityAlert] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    if (userInfo) {
      if (userInfo.catalog_favorite) {
        setFavoriteList(JSON.parse(userInfo?.catalog_favorite));
      }
    }
  }, [setFavoriteList, userInfo]);

  const getAllApps = useCallback(() => {
    if (!userInfo || Object.keys(userInfo).length === 0) {
      getApps().then((regularServices) => {
        setAllCatalogList(regularServices);
      });
    }
  }, [accessToken, userInfo]);

  useEffect(() => {
    getAllApps();
  }, [getAllApps]);

  const updateFavorites = useCallback((slug: string, remove: boolean) => {
    setFavoriteList((prevList) => {
      if (remove) {
        return prevList.filter((item) => item !== slug);
      } else {
        return [...prevList, slug];
      }
    });
  }, []);

  return (
    <div>
      <TopBar />
      <CategoryBannerContextProvider>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          autoHideDuration={3000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert
            onClose={() => setOpenAlert(false)}
            severity={severityAlert}
            sx={{ width: "100%" }}
          >
            {messageAlert}
          </Alert>
        </Snackbar>
        <MainBannerContainer>
          <HeaderContainer />
          <SearchContainer className="xvia-home-search" />
        </MainBannerContainer>
        <Element name="serviceByCategory">
          <PublicRelatedCards />
        </Element>
        <Element name="userRelatedWidgets">
          <UserRelatedWidgetsContainer />
        </Element>
        <Element name="favoriteServices">
          <FavoriteServicesContainer
            allCatalogList={allCatalogList || []}
            favoriteList={favoriteList || []}
            onFavoriteUpdate={updateFavorites}
            setMessageAlert={setMessageAlert}
            setSeverityAlert={setSeverityAlert}
            setOpenAlert={setOpenAlert}
          />
        </Element>

        <Element name="relevantServices">
          <RelevantServicesContainer
            allCatalogList={allCatalogList || []}
            favoriteList={favoriteList || []}
            onFavoriteUpdate={updateFavorites}
            setMessageAlert={setMessageAlert}
            setSeverityAlert={setSeverityAlert}
            setOpenAlert={setOpenAlert}
          />
        </Element>
        <Element name="topServices">
          <TopServicesContainer
            allCatalogList={allCatalogList || []}
            favoriteList={favoriteList || []}
            onFavoriteUpdate={updateFavorites}
            setMessageAlert={setMessageAlert}
            setSeverityAlert={setSeverityAlert}
            setOpenAlert={setOpenAlert}
          />
        </Element>
        <Element name="appsContainer">
          <AppsContainer />
        </Element>
        <Element name="category">
          <CategoryBannerContainer />
        </Element>
        <Element name="indicators">
          <IndicatorsSectionContainer />
        </Element>
        <Element name="downloadAppMobile">
          <DownloadAppMobile />
        </Element>
        <Element name="ombudsman">
          <OmbudsmanContainer />
        </Element>
        <Element name="socialNetwork">
          <SocialNetworkContainer />
        </Element>
      </CategoryBannerContextProvider>
      <FooterContainer />
    </div>
  );
};

import { Box, Divider, Stack, Typography } from "@mui/material";
import { useCallback } from "react";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { UserVehicle } from "../../apis/user-related-services";

import "./vehicle-card.css";
import { VehicleMarkLogo } from "./vehicle-mark-logo";

type VehicleCardProps = {
  data: UserVehicle;
  onClick?: () => void;
};

function VehicleCard({ data, onClick }: VehicleCardProps) {
  let message;
  let alertBackgroundColor = "#3FB871"; // Cor padrão para veículo regular

  const countPendencies = () => {
    let count = 0;
    if (data.restricaoJudicial) count++;
    if (data.restricaoAdministrativa) count++;
    if (data.restricaoTributaria) count++;
    if (data.licenciamentoAtrasado) count++;
    return count;
  };

  const pendenciesCount = countPendencies();

  // if (pendenciesCount > 0) {
  // alertBackgroundColor = "#FDB913";
  // if (data.quantMultas && data.quantMultas > 0) {
  // message = `Veículo pendente de regularização`;
  // } else {
  // message = `Veículo pendente de regularização`;
  // }
  // } else if (data.quantMultas && data.quantMultas > 0) {
  //   message = `Seu veículo tem ${data.quantMultas} multa(s)`;
  //   alertBackgroundColor = "#FDB913"; // Cor para veículo com multas
  // }

  const publicUrl = process.env.PUBLIC_URL || "http://localhost:3000";

  const isVeichleCar = (veichleType: UserVehicle["tipoVeiculo"]) =>
    veichleType === "AUTOMOVEL";

  const getVeichleLink = (
    mark: UserVehicle["marca"],
    veichleType: UserVehicle["tipoVeiculo"]
  ) => {
    const lowedMark = mark?.toLocaleLowerCase();

    const isHondaCar =
      lowedMark?.includes("honda") && isVeichleCar(veichleType);

    if (isHondaCar) {
      return `${publicUrl}/images/car/honda-carro.svg`;
    }

    return `${publicUrl}/images/car/${lowedMark}.svg`;
  };

  const onLoad = useCallback(() => {
    window.open("/app/app-meus-veiculos", "_self");
  }, []);

  return (
    <>
      <Stack
        direction="column"
        sx={(theme) => ({
          backgroundColor: "#fff",
          borderRadius: "0.275rem",
          width: "20rem",
          marginRight: "0.5rem",
          overflow: "hidden",
          boxShadow: "1px 2px 4px 0px #ABABAB1A",
          border: "1px solid #E0E3EB",
          [theme.breakpoints.down("sm")]: { width: "15.5rem", marginRight: 0 },
        })}
        onClick={onLoad}
      >
        {/* Label Situation */}
        <Stack
          direction="row"
          justifyContent="center"
          sx={(theme) => ({
            width: "100%",
            backgroundColor: pendenciesCount > 0 ? "#FDB913" : "#007932",
            color: pendenciesCount > 0 ? "#000" : "#fff",
            p: 1,
          })}
        >
          <Typography align="center">
            {pendenciesCount > 0
              ? "Veículo pendente de regularização"
              : "Seu veículo está regularizado"}
          </Typography>
        </Stack>
        {/* Info */}
        <Stack direction="column" sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({ width: "100%" })}
          >
            <Box sx={{ width: "2.5rem" }}>
              {data?.marca && (
                // FIXME: Add vehicle type info in userInfo
                // Type = 6 is always a car
                <VehicleMarkLogo mark={data?.marca} type={6} />
              )}
            </Box>
            <Stack direction="column" alignItems="flex-end">
              <Typography
                sx={(theme) => ({
                  fontWeight: 700,
                  [theme.breakpoints.down("sm")]: { fontSize: "0.85rem" },
                })}
              >
                {data?.marca}
              </Typography>
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                })}
              >
                {data?.modelo}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ my: 1, width: "100%", paddingX: "2rem" }}></Divider>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({ width: "100%" })}
          >
            <Stack direction="column">
              <Typography
                sx={(theme) => ({
                  fontWeight: 700,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                  },
                })}
              >
                Licenciamento
              </Typography>
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                })}
              >
                {data?.anoLicenciamento}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="flex-end">
              <Typography
                sx={(theme) => ({
                  fontWeight: 700,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                  },
                })}
              >
                Placa
              </Typography>
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                })}
              >
                {data?.placa}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ my: 1, width: "100%", paddingX: "2rem" }}></Divider>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({ width: "100%" })}
          >
            <Stack direction="column">
              <Typography
                sx={(theme) => ({
                  fontWeight: 700,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                  },
                })}
              >
                Renavam
              </Typography>
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                })}
              >
                {data?.renavam}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="flex-end">
              <Typography
                sx={(theme) => ({
                  fontWeight: 700,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                  },
                })}
              >
                Pendencias
              </Typography>
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                })}
              >
                {pendenciesCount}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default VehicleCard;

import {
  Alert,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { FC, MouseEventHandler, useState } from "react";
import { Info } from "../../info/info";
import { Rating } from "../../rating/rating";

import { faEarthAmericas, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccessProfile, ResultCategory } from "../../../apis/detail-catalog";

import { useWebgate } from "@xvia/webgate-connect";
import { setFavorites } from "../../../apis/dashboard";
import "./catalog-card.css";

export interface CatalogCardProps {
  title?: string;
  rating?: number;
  totalRatings?: number;
  isFree?: boolean;
  isOnline?: boolean;
  isSelfService?: boolean;
  resultCategory?: ResultCategory;
  accessProfile?: AccessProfile;
  lastUpdate?: string;
  link?: string;
  onFavoriteClick?: MouseEventHandler;
  onPdfClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
  onRatingClick?: (rating: number) => void;
  isFavorite?: boolean;
  slug?: string;
}

export const CatalogCard: FC<CatalogCardProps> = ({
  title,
  rating = 0,
  totalRatings = 0,
  isFree,
  isOnline,
  isSelfService,
  resultCategory,
  accessProfile,
  link,
  lastUpdate,
  onFavoriteClick,
  onPdfClick,
  onServiceClick,
  onRatingClick,
  isFavorite,
  slug,
}) => {
  const { accessToken, userInfo } = useWebgate();
  const [severityAlert, setSeverityAlert] = useState<"success" | "error">(
    "success"
  );
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [messageAlert, setMessageAlert] = useState<string>("");

  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await setFavorites(accessToken, {
      slug,
      remove: isFavorite ?? false,
    })
      .then(() => {
        setSeverityAlert?.("success");
        setOpenAlert?.(true);
        setMessageAlert?.(
          isFavorite
            ? "Serviço removido dos favoritos"
            : "Serviço favoritado com sucesso"
        );
        onFavoriteClick?.(e);
      })
      .catch(() => {
        setSeverityAlert?.("error");
        setOpenAlert?.(true);
        setMessageAlert?.("Erro ao favoritar serviço");
      });
  };

  return (
    <div className={"xvia-catalog-card"}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={severityAlert}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={5} md={5} lg={5} xl={6.5}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography className="xvia-catalog-card__title_text">
              {title}
            </Typography>
            {userInfo && (
              <button
                onClick={handleFavoriteClick}
                className={`xvia-service-card__favorite ${
                  isFavorite ? "is-favorite" : ""
                }`}
                aria-label={
                  isFavorite
                    ? "Remover dos favoritos"
                    : "Adicionar aos favoritos"
                }
              >
                <FontAwesomeIcon icon="star" size="lg" />
              </button>
            )}
          </div>
          <div className="xvia-catalog-card__subtitle_text">
            {resultCategory && <div>{resultCategory.name}</div>}
            {resultCategory && isSelfService && <div> - </div>}
            {isSelfService && <div>Autosserviço</div>}
          </div>
          <div className={"xvia-catalog-card__info_content"}>
            <span>
              <Rating
                value={rating}
                readOnly={false}
                onClickItem={onRatingClick}
              />
            </span>
            {!!lastUpdate && (
              <Info text={`Atualizado há ${lastUpdate}`} icon={"fa-clock"} />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} xl={1.5}>
          <div className="xvia-catalog-card__info_content">
            {isOnline && (
              <div>
                <FontAwesomeIcon
                  icon={faEarthAmericas}
                  className="fa-light"
                  size="2xl"
                ></FontAwesomeIcon>
                <Info text={"Online"} />
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
          <div className="xvia-catalog-card__info_content">
            <IconButton
              aria-label="Favorite"
              onClick={onPdfClick}
              size="large"
              color="primary"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                size="xl"
                style={{ color: "#ef4123" }}
              />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          {link && (
            <Button
              className="xvia-button xvia-button---primary"
              variant="contained"
              onClick={onServiceClick}
              startIcon={<FontAwesomeIcon icon={["fas", "right-to-bracket"]} />}
            >
              Acessar Serviço Digital
            </Button>
          )}
          {!link && (
            <Info
              text={"Serviço realizado presencialmente"}
              icon={"fa-location-dot"}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
